<template>
  <div class="p-5">
    <vc-app-title size="xl" center />

    <div class="platter mx-auto mt-5">
      <h2 v-if="!isNewAccount" class="text-center">Reset Password</h2>
      <h2 v-else class="text-center">Set Password</h2>
      <p class="text-center">Enter a new password for your account.</p>

      <b-form-group label="New Password">
        <password
          v-model="password"
          @score="passwordScore = $event"
          @feedback="passwordFeedback = $event"
        />
      </b-form-group>

      <b-form-group label="Repeat New Password">
        <password v-model="repeatPassword" />
      </b-form-group>

      <b-button
        :disabled="!isPasswordValid"
        block
        variant="primary"
        class="mt-4"
        @click="reset"
      >
        <strong>Change Password</strong>
      </b-button>

      <b-alert variant="danger" :show="!!errorMessage" class="mt-3 mb-0">
        {{ errorMessage }}
      </b-alert>
    </div>
  </div>
</template>

<script>
const Password = () =>
  import(
    /* webpackChunkName: "passwordStrength" */ "vue-password-strength-meter"
  );

export default {
  name: "ResetPassword",
  components: { Password },
  props: {
    token: { type: String }
  },
  data() {
    return {
      errorMessage: undefined,
      password: undefined,
      repeatPassword: undefined,
      passwordScore: 0,
      passwordFeedback: undefined
    };
  },
  computed: {
    isNewAccount() {
      return !!this.$route.query.n;
    },
    isPasswordValid() {
      if (this.password !== this.repeatPassword) return false;
      if (this.passwordScore < 2) return false;
      return true;
    }
  },
  mounted() {
    const appName = this.$store.getters.appName;
    if (this.isNewAccount) {
      document.title = `Set Password | ${appName}`;
    } else {
      document.title = `Reset Password | ${appName}`;
    }
  },
  methods: {
    reset() {
      this.$store
        .dispatch("resetUserPassword", {
          token: this.token,
          password: this.password
        })
        .then(
          d => {
            this.$router.replace({
              name: "Login",
              params: {
                logOut: true
              },
              query: {
                user: d.user,
                reset: 1
              }
            });
          },
          e => {
            this.errorMessage = e.message;
          }
        );
    }
  }
};
</script>

<style scoped lang="scss">
.platter {
  max-width: 450px;
}
</style>